import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { ErrorBoundary } from "react-error-boundary";
import { reportCrashError } from "@http/endpoints/slackReports";
import Spinner from "@common/spinner/Spinner";
import ErrorComponent from "./components/common/ErrorComponent";

const Main = React.lazy(() => import("./components/pages/Main"));
const LoginPage = React.lazy(() =>
  import("./components/pages/unauthenticated/login/LoginPage")
);

function App() {
  const { isAuthenticated } = useStoreState((state) => state.user);
  const { showSpinner } = useStoreState((state) => state);

  const handleError = async (error, info) => {
    let message = `Error message (BUM Admin): ${
      error?.message || "no error message"
    }, Location: ${info?.componentStack || "no location provided"}`;
    const { data } = await reportCrashError({ message });
    if (data) console.log(data);
  };

  return (
    <Suspense fallback={<Spinner show={true} />}>
      <ErrorBoundary FallbackComponent={ErrorComponent} onError={handleError}>
        <Router>{isAuthenticated ? <Main /> : <LoginPage />}</Router>
        <Spinner show={showSpinner} />
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
