import {action, computed} from "easy-peasy";
import {LocalStorage} from "@/helpers/getLocalStorage";
import {LOCAL_STORAGE_KEYES} from "@/helpers/localStorageTypes";

export const initialTableSettings = {
    page: 1,
    perPage: 15,
    total: 0,
    filter: undefined,
    sort: undefined
}


const tableSettingsModel = {
    settings: LocalStorage.isTableSettingsExist() ? LocalStorage.getTableSettings() : {},
    setSettings: action((state, payload) => {
        state.settings[payload.tableName] = {
            ...(state.settings[payload.tableName] || {}),
            ...payload.data
        }
    }),
    setToLocalStorage: action((state) => {
        localStorage.setItem(LOCAL_STORAGE_KEYES.BUM_TABLE_SETTINGS, JSON.stringify(state.settings))
    }),
    setTotal: action((state, payload) => {
        state.settings[payload.tableName] = {
            ...(state.settings[payload.tableName] || {}),
            total: payload.data || 0
        }
    }),
    clearFilter: action((state, payload) => {
        const settingsData = state.settings[payload.tableName]
        if (!settingsData) return
        const filter = Object.keys(settingsData.filter).filter(objKey => objKey !== payload.field)
            .reduce((newObj, key) => {
                newObj[key] = settingsData.filter[key];
                return newObj;
            }, {});
        state.settings[payload.tableName] = {
            ...state.settings[payload.tableName],
            page: 1,
            filter: {
                ...(filter || {})
            }
        }
    }),
    setFilter: action((state, payload) => {
        const settingsData = state.settings[payload.tableName]
        if (!settingsData) return
        state.settings[payload.tableName] = {
            ...state.settings[payload.tableName],
            page: 1,
            filter: {
                ...(state.settings[payload.tableName]?.filter || {}),
                ...(payload?.data || {})
            }
        }
    }),
    resetFilter: action((state, payload) => {
        const settingsData = state.settings[payload.tableName]
        if (!settingsData) return
        state.settings[payload.tableName] = {
            ...state.settings[payload.tableName],
            page: 1,
            filter: {}
        }
    }),
    setPagination: action((state, payload) => {
        const settingsData = state.settings[payload.tableName]
        if (!settingsData) return
        state.settings[payload.tableName] = {
            ...settingsData,
            ...(payload.data || {})
        }
    }),
    setSort: action((state, payload) => {
        const settingsData = state.settings[payload.tableName]
        if (!settingsData) return
        state.settings[payload.tableName] = {
            ...state.settings[payload.tableName],
            sort: payload.data || undefined
        }
    }),
    getSettings: computed((state) => {
        return (tableName) => state?.settings?.[tableName]
    }),
};

export default tableSettingsModel;
