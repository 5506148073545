import axios from "axios";

import { authRequest, authResponse } from "./interceptors/authentication";

const API_URL_PROD = process.env.REACT_APP_API_URL_PROD;
const API_URL_DEV = process.env.REACT_APP_API_URL_DEV;

const url = process.env.NODE_ENV !== "production" ? API_URL_DEV : API_URL_PROD;

const http = axios.create({
  baseURL: url + "/api/admin",
});

http.interceptors.request.use(authRequest);
http.interceptors.response.use(...authResponse);

export default http;
