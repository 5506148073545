import http from "@http";
import toResponse from "@helpers/toResponse/toResponse";

import toResponseWithSpinner from "@helpers/toResponse/toResponseWithSpinner";

export const searchUsers = async (queryKey, { params, q }) => {
  const { data } = await http.post(
    `/users/search?page=${params.page}&per_page=${params.per_page}`,
    { q }
  );
  return data;
};

export const getUsers = async (queryKey, { params }) => {
  const { data } = await http.get(`/users`, { params });
  return data;
};

export const getUserByID = async (queryKey, { id }) => {
  const { data } = await http.get(`/users/${id}`);
  return data;
};

export const getAllSubscriptions = async (queryKey, { params, q }) => {
  const { data } = await http.get(`/subscription-histories`, { params });
  return data;
};

export const getDepositSubscriptions = async (queryKey, { params }) => {
  const { data } = await http.get(`/deposit-histories`, {
    params,
  });
  return data;
};
export const getUser = () => toResponse(http.get("/user"));
export const deleteUser = (id) => toResponse(http.delete(`/users/${id}`));

export const loginUser = (values) =>
  toResponseWithSpinner(http.post("/login", values));

export const logoutUser = () => toResponse(http.get("/logout"));
export const blockUser = (id, note) => {
  return toResponseWithSpinner(
    http.post(`/users/block/${id}`, note?.length ? { note } : "")
  );
};

export const changeUsername = (values) =>
  toResponseWithSpinner(http.post("/change-user-username", values));

export const changeEmail = (values) =>
  toResponseWithSpinner(http.post("/change-user-email", values));

export const changeDeposit = (values) =>
  toResponseWithSpinner(http.post("/change-user-deposit", values));

export const changePassword = (values) =>
  toResponseWithSpinner(http.post("/change-user-password", values));

export const getStoredMethods = async (queryKey, { params }) => {
  const { data } = await http.get(`/stored-methods`, { params });
  return data;
};

export const getStoredMethod = async (queryKey, { id }) => {
  const { data } = await http.get(`/stored-methods/${id}`);
  return data;
};

export const updateMethod = (id, values) =>
  toResponseWithSpinner(http.put(`/stored-methods/${id}`, values));

export const cancelSubscription = (id, values) =>
  toResponseWithSpinner(
    http.post(`/subscription-histories/cancel/${id}`, values)
  );

export const freeSubscription = (values) =>
  toResponseWithSpinner(http.post(`/subscription-histories/free`, values));

export const addDeposit = (id, values) =>
  toResponseWithSpinner(http.post(`/deposit-histories/credit/${id}`, values));

export const getUserToken = (id) =>
  toResponseWithSpinner(http.get(`/admin-login/${id}`));

export const getMoviesHistory = async (queryKey, { params }) => {
  const { data } = await http.get(`/watch-movies-history`, { params });
  return data;
};
export const getScenesHistory = async (queryKey, { params }) => {
  const { data } = await http.get(`/watch-scenes-history`, { params });
  return data;
};
