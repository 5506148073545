import React from "react";
import { Spin } from "antd";

const Spinner = ({ show }) => {
  return (
    <div id="spinner" className={`${show ? "show-spinner" : "hide-spinner"}`}>
      <div className="spinner">
        <Spin size="large" />
      </div>
    </div>
  );
};

export default Spinner;
