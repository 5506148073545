import store from "@store";

export default (promise) => {
  let { setShowSpinner } = store.getActions();
  setShowSpinner(true);

  return promise
    .then((data) => {
      setShowSpinner(false);
      return { data };
    })
    .catch((error) => {
      setShowSpinner(false);

      return { error };
    });
};
