import React from "react";
import { Result, Button } from "antd";

const ErrorComponent = () => {
  return (
    <Result
      status="warning"
      title="There are some problems with your operation."
      extra={
        <Button
          type="primary"
          key="console"
          onClick={() => window.location.replace("/")}
        >
          Go to Home Page
        </Button>
      }
    />
  );
};

export default ErrorComponent;
