import { computed, persist, action, thunk } from "easy-peasy";
import { getUser } from "@http/endpoints/users";

const userModel = {
  userInfo: {},
  session: persist({ sessionInfo: {} }, { storage: "localStorage" }),
  setUser: action((state, payload) => {
    state.userInfo = payload;
  }),
  setSession: action((state, payload) => {
    if (payload) {
      state.session.sessionInfo = {
        refresh_token: payload.refresh_token,
        token: payload.token,
      };
    } else {
      state.session.sessionInfo = {};
    }
  }),
  setAuthInfo: thunk(async (actions, payload) => {
    await actions.setSession(payload);
    await actions.updateUser();
  }),
  updateUser: thunk(async (actions) => {
    const { data, error } = await getUser();
    if (error) console.log(error);
    if (data) {
      let temp = {
        ...data.data.data.user,
        ...data.data.data.user_info,
      };
      await actions.setUser(temp);
    }
  }),

  removeAuthInfo: thunk(async (actions) => {
    await actions.setUser({});
    await actions.setSession(null);
  }),
  isAuthenticated: computed((state) => {
    if (Object.keys(state.session.sessionInfo).length) return true;
    else return false;
  }),
};

export default userModel;
