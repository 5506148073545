import {LOCAL_STORAGE_KEYES} from "./localStorageTypes";

export const getLocalStorageObj = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

export const getLocalStorageItem = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export const removeLSItem = (key) => localStorage.removeItem(key);



export class LocalStorage {

  static getLocalStorageObject (key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      return null;
    }
  }
  static setLocalStorageItem (key, value) {

  }

  static getLocalStorageItem (key) {
    return localStorage.getItem(key);
  }

  static isKeyExist (key)  {
    return !!getLocalStorageItem(key)
  }

  static getTableSettings () {
    return this.getLocalStorageObject(LOCAL_STORAGE_KEYES.BUM_TABLE_SETTINGS)
  }

  static isTableSettingsExist () {
    return !!this.isKeyExist(LOCAL_STORAGE_KEYES.BUM_TABLE_SETTINGS)
  }

}