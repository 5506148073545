import store from "@store";

export function authRequest(config) {
  const lang = localStorage.getItem("i18nextLng");
  const token = store.getState().user.session.sessionInfo.token;
  if (lang) {
    config.headers["X-localization"] = lang;
  }
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  config.headers["X-admin"] = "true";

  return config;
}

export const authResponse = [
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.message === "Unauthorized") {
      if (store.getState().user.isAuthenticated) {
        store.getActions().user.removeAuthInfo();
      }
    }
    return Promise.reject(error);
  },
];
