import userModel from "./userModel";
import { action } from "easy-peasy";
import tableSettingsModel from "./tableSettingsModel";

const storeModel = {
  user: userModel,
  showSpinner: false,
  setShowSpinner: action((state, payload) => {
    state.showSpinner = payload;
  }),
  showSkeleton: false,
  setShowSkeleton: action((state, payload) => {
    state.showSkeleton = payload;
  }),
  tableSettings: tableSettingsModel
};
export default storeModel;
